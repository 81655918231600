import React, { ReactNode } from "react";

export const getChildren = (children: any) => {
    const divs: Array<ReactNode> = [];
    React.Children.forEach(children, (child: any) => {
        divs.push(child);
    });

    return divs;
};

export default ( props : { children : any, gap ?: number, invert ?: boolean, widthRight : number }) => {
    const children: any[] = getChildren( props.children );
    const gap : number = props.gap ? props.gap : 20
    const widthLeft : number | string = `calc(100% - ${props.widthRight}px)`
    const widthRight : number | string = props.widthRight ? props.widthRight - gap : 320 - gap
    const childrenLeft : any = children && children.length > 0 ? children[0] : []
    const childrenRight: any = children && children.length > 1 ? children[1] : []
    return (
        <div style={{ width : '100%', textAlign : 'center'}}>
            
            {/* <div style={{ paddingLeft : 20, paddingRight : 20 }}> */}
                <div style={{ verticalAlign : 'top', display : 'inline-block', width : props.invert ? widthRight : widthLeft }}>
                    { childrenLeft }
                </div>
                <div style={{ verticalAlign : 'top', display : 'inline-block', marginLeft : gap, width : props.invert ? widthLeft : widthRight }}>
                    { childrenRight }
                </div>

            {/* </div> */}
        </div>
    )
}