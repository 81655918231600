import { css } from "glamor";
import React from "react";
import { Component } from "react";

const VERSION : number = 2

export const on = (mediavalue: number) => {
    const mediaquery: string = `@media (max-width: ${mediavalue}px)`;
    return mediaquery;
};


export default ( props : { children ?: any, on ?: number, b4?: number, displayOverride ?: any  }) => {

    const params = {
        ...( props.b4 ? {
            display : 'none'
        } : {}),
        ...( props.b4 ? {
            [on( props.b4 )]:{ 
                display : 'block'
            }
        } : {}),
        ...( props.on ? {
            [on( props.on )]:{ 
                display : 'none'
            } 
        } : {})
    }

    return (<>
        <div {...css(params)}>
            {props.children}
        </div>
    </>)
}


