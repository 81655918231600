import { Component } from 'react';
import X from './X';
import Y from './Y';

export default class XY extends Component<{
    style?: Record<string, any>;
    children ?: any, 
    wrapper?: Record<string, any>;
    align?: 'space-evenly' | 'space-between' | 'center' | 'left' | 'right';
    show?: boolean;
}> {
    render() {
        const style: Record<string, any> = {
            //float: 'clear',
            ...this.props.style
        };

        const wrapper: Record<string, any> = {
            //float: 'clear',
            ...this.props.wrapper
        };

        return (
            <X
                align={this.props.align ? this.props.align : 'center'}
                style={{
                    backgroundColor: this.props.show ? 'blue' : 'transparent',
                    ...wrapper
                }}
            >
                <Y style={{ width: 'auto', ...style }}>{this.props.children}</Y>
            </X>
        );
    }
}
