import { css } from 'glamor';
import React, { Component } from 'react';


export interface Ref { [name: string]: any; }

export const onDefault: string = 'onDefault';

export default class Rsp extends Component<{
    styles: Ref;
    onClick?: () => void;
    children?: any
}> {
    render() {
        let styles: Record<string, any> = {};

        Object.keys(this.props.styles).forEach((key: string) => {
            if (key === 'onDefault')
                styles = {
                    ...styles,
                    ...this.props.styles[key]
                };
            else
                styles = {
                    ...styles,
                    [key]: {
                        ...this.props.styles[key]
                    }
                };
        });

        return (
            <div onClick={this.props.onClick} {...css(styles)}>
                {this.props.children}
            </div>
        );
    }
}
