//

import { css } from "glamor"
import React from "react"
export const onHover: string = ':hover';

// VERSION : 1.0.0
export default ( props : { hover ?: boolean, children ?: any, style ?: any, opacity ?: number, opacityOnHover ?: number  } ) => {

    const opacity : number = props.opacity ? props.opacity : 0.2
    const opacityOnHover : number = props.opacityOnHover ? props.opacityOnHover : 0.35

    // optimization -> reduce javascript usage as much as possible. Only use non-css when necessary
    if(props.hover || props.opacityOnHover !== undefined ) return (
        <div {...css({
            display : 'inline-block',
            filter: `drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, ${opacity}))`,
            [onHover] : {
                filter: `drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, ${opacityOnHover}))`,
            },
            ...props.style
        })}>
            {props.children}
        </div>
    )


    return (<div style={{
        display : 'inline-block',
        filter: `drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, ${opacity}))`,
        ...props.style
    }}>
        {props.children}
    </div>)
}